export const UNAVAILABLE_DELIVERY_DAYS_TYPES = {
  DELIVERY_RESTRICTED: 'DELIVERY_RESTRICTED',
  EXPRESS_DELIVERY_RESTRICTED: 'EXPRESS_DELIVERY_RESTRICTED',
  SUPPLIER_DELIVERY_RESTRICTED: 'SUPPLIER_DELIVERY_RESTRICTED',
  DELIVERY_WEEK_RESTRICTED: 'DELIVERY_WEEK_RESTRICTED',
  SUPPLIER_DELIVERY_WEEK_RESTRICTED: 'SUPPLIER_DELIVERY_WEEK_RESTRICTED'
}

export const EVENT_TYPES = [
  {
    title: 'Недоступна общая доставка',
    type: UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_RESTRICTED,
    status: 'НОД',
    color: '#ea6362',
    isAddEvent: true
  },
  {
    title: 'Недоступна экспресс доставка',
    type: UNAVAILABLE_DELIVERY_DAYS_TYPES.EXPRESS_DELIVERY_RESTRICTED,
    status: 'НЭД',
    color: '#99caff',
    isAddEvent: true
  },
  {
    title: 'Недоступна доставка поставщика',
    type: UNAVAILABLE_DELIVERY_DAYS_TYPES.SUPPLIER_DELIVERY_RESTRICTED,
    status: 'НДП',
    color: '#FFA500',
    isAddEvent: true
  },
  {
    title: 'Недоступные дни недели',
    type: UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_WEEK_RESTRICTED,
    status: 'НДН',
    color: '#423189',
    isAddEvent: false
  },
  {
    title: 'Недоступные дни недели поставщика',
    type: UNAVAILABLE_DELIVERY_DAYS_TYPES.SUPPLIER_DELIVERY_WEEK_RESTRICTED,
    status: 'НДНП',
    color: '#37c53a',
    isAddEvent: false
  }
]
