<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button
        :disabled="!hasUserCode"
        size="small"
        type="primary"
        @click="submitForm()"
      >
        Cоздать заказ
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="order"
      ref="form"
      :rules="rules"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Статус заказа">
        <el-select
          :class="$style.select"
          v-model="order.state.id"
          placeholder="Выберите cтатус заказа"
        >
          <el-option
            v-for="item in orderStatuses"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Статус оплаты">
        <el-select
          :class="$style.select"
          v-model="order.paymentStatus.id"
          placeholder="Выберите cтатус оплаты"
        >
          <el-option
            v-for="item in paymentStatusOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <h5 :class="$style.infoUser">
        Если в поле получателя указан номер, которого нет в списке, при нажатии
        на кнопку "Выслать&nbsp;код" будет создан новый аккаунт пользователя,
        который сразу же можно отредактировать
      </h5>

      <el-form-item label="Выберите получателя" prop="recipientPhone">
        <Autocomplete
          v-model="order.phone"
          :search="phoneSearch"
          placeholder="Поиск получателя"
          :valueNames="['phone', 'lastName', 'firstName']"
          :clearable="true"
          @querySearch="getUsersList"
          @selectItem="handleSelectUser"
          @input="handleSetInput"
          @loadMore="loadMoreUsers"
        />
        <el-button
          size="medium"
          :class="$style.codeBtn"
          type="primary"
          @click="getUserCode"
        >
          Выслать код
        </el-button>
        <p v-if="isCodeNeedResend" :class="$style.dangerText">
          Необходимо заново получить код
        </p>
      </el-form-item>
      <div v-if="hasUserCode">
        <el-form-item v-if="hasUserCode" label="Код" prop="code">
          <el-input v-model="order.code"></el-input>
        </el-form-item>
        <el-form-item label="Тип доставки" prop="deliveryType">
          <el-select
            :class="$style.select"
            v-model="order.deliveryType"
            placeholder="Выберите тип"
            @change="changeDeliveryType"
          >
            <el-option
              v-for="item in deliveryOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div v-if="order.deliveryType === $options.DELIVERY_TYPE.DELIVERY">
          <el-form-item label="Экспресс-доставка">
            <el-checkbox
              v-model="order.isExpressDelivery"
              @change.native="handleChangeIsExpressDelivery"
            />
          </el-form-item>
          <el-form-item label="Адрес доставки" prop="deliveryAddress">
            <el-select
              :class="$style.select"
              v-model="order.deliveryAddress"
              value-key="id"
              :placeholder="getDeliveryAddressString()"
              filterable
              @change="handleSelectDeliveryAddress"
            >
              <el-option
                v-for="item in deliveryAddresses"
                :key="item.id"
                :label="item.city + ', ' + item.street"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-button
              size="medium"
              :class="$style.deliveryBtn"
              type="primary"
              @click="isShowDeliveryAdressModal = true"
            >
              Создать адрес доставки
            </el-button>
          </el-form-item>
          <el-form-item label="Дата доставки" prop="deliveryAt">
            <AdwCalendar
              id="orderDatePicker"
              :value="order.deliveryAt"
              :disabled-dates="disabledDates"
              minimum-view="day"
              maximum-view="day"
              placeholder="Дата доставки"
              is-hide-icon
              @selected="handleChangeDeliveryDate"
            />
          </el-form-item>
          <el-form-item label="Цена доставки">
            <el-input
              v-model.number="order.deliveryPrice"
              :readonly="isDeliveryPriceReadOnly"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="Способ оплаты" prop="paymentType">
          <el-select
            :class="$style.select"
            v-model="order.paymentType.id"
            placeholder="Выберите способ оплаты"
          >
            <el-option
              v-for="item in paymentTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :disabled="
                !availablePaymentTypes.find(
                  (paymentType) => paymentType.id === item.id,
                )
              "
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Имя получателя" prop="client.firstName">
          <el-input v-model="order.client.firstName"></el-input>
        </el-form-item>
        <el-form-item label="Фамилия получателя" prop="client.lastName">
          <el-input v-model="order.client.lastName"></el-input>
        </el-form-item>
        <el-form-item label="Телефон" prop="client.phone">
          <el-input v-model="order.client.phone" readonly></el-input>
        </el-form-item>
        <el-form-item label="Почта" prop="client.email">
          <el-input v-model="order.client.email"></el-input>
        </el-form-item>
      </div>
      <h3>Состав заказа</h3>
      <h5 :class="$style.warningText">
        Для того, чтобы Ваш промокод применился к товарам корректно -
        рекомендуется для начала добавить продукты для заказа и после применить
        промокод
      </h5>
      <el-form-item label="Выберите новый товар">
        <div :class="$style.search">
          <Autocomplete
            :search="productSearch"
            placeholder="Поиск товара"
            :valueNames="['name']"
            @querySearch="getProductsList"
            @selectItem="handleSelectProduct"
            @input="productSearch = $event"
            @loadMore="loadMoreProducts"
          />
        </div>
      </el-form-item>
      <el-table :data="order.products" stripe>
        <el-table-column label="Изображение">
          <template slot-scope="scope">
            <img
              v-if="scope.row.images"
              width="auto"
              height="70"
              :src="$configData.s3_link + scope.row.images[0].original"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Наименование товара">
        </el-table-column>
        <el-table-column label="Количество">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.quantity"
              controls-position="right"
              :min="scope.row.stepQuantity"
              :max="scope.row.stocks?.[0].stock"
              :step="scope.row.stepQuantity"
              @change="changeProductQuantityAndDiscountPrice(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="Цена по скидке">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.discountPrice"
              controls-position="right"
              :min="1"
              :max="maxDiscountPrice"
              @change="changeProductQuantityAndDiscountPrice(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="Скидка">
          <template slot-scope="scope">
            {{ scope.row.discount ? scope.row.discount : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="Суммарная цена">
          <template slot-scope="scope">
            {{
              scope.row.quantity *
              getPrice(
                scope.row.discountPrice,
                scope.row.price,
                scope.row.discount,
              )
            }}
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot-scope="scope">
            <div :class="$style.buttons">
              <a
                :href="
                  $configData.addwine_link + '/catalogue/' + scope.row.slug
                "
                target="_blank"
              >
                <Icon name="eye" :class="$style.icon" />
              </a>
              <Icon
                name="trash"
                :class="$style.icon"
                @click="removeProduct(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div :class="$style.bonuses">
        <p :class="$style.title">Списать бонусы</p>
        <p :class="$style.balance">
          баланс:
          {{
            (
              bonuses.count * paymentSettings.bonusWriteOffRatio
            ).toLocaleString()
          }}
          <span>&#8381;</span>
        </p>
        <div :class="$style.content">
          <div :class="$style.buttons">
            <adw-button
              color="light-gray"
              :class="[$style.button, { [$style.active]: !bonuses.isWriteOff }]"
              :disabled="bonuses.count <= 0 || !productAmount"
              @click="bonuses.isWriteOff = false"
            >
              не списывать
            </adw-button>
            <adw-button
              color="light-gray"
              :class="[$style.button, { [$style.active]: bonuses.isWriteOff }]"
              :disabled="bonuses.count <= 0 || !productAmount"
              @click="
                bonuses.isWriteOff = true
                amountBonusWriteOff = maxAmountBonusWriteOff
              "
            >
              списать до {{ maxAmountBonusWriteOff }}
              <span>&#8381;</span>
            </adw-button>
          </div>
          <adw-input
            v-if="bonuses.isWriteOff"
            v-model="amountBonusWriteOff"
            placeholder="Введите кол-во бон.руб."
            :error="errors.bonuses"
            :class="$style.input"
          />
        </div>
      </div>
      <div :class="$style.promoCode">
        <p :class="$style.title">Применить промокод</p>
        <form :class="$style.form" @submit.prevent="submitPromo">
          <adw-input
            :value="promoCode.code"
            :class="$style.input"
            placeholder="Введите промокод"
            :error="promoCode.error"
            :success="promoCode.success"
            @input="
              (v) => {
                promoCode.code = v.toUpperCase()
              }
            "
          />
          <adw-button
            size="big"
            color="secondary"
            type="submit"
            :disabled="!promoCode.code || !order?.products?.length"
          >
            применить
          </adw-button>
        </form>
      </div>
      <h1 :class="$style.price">Сумма: {{ totalPrice.toFixed(2) }} ₽</h1>
    </el-form>
    <DeliveryAddressModal
      :isShow="isShowDeliveryAdressModal"
      :address="order.deliveryAddress"
      @close="isShowDeliveryAdressModal = false"
      @save="handleSubmitDeliveyAddressModal"
    ></DeliveryAddressModal>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp'
import notifications from '@/mixins/notifications'

import { PROMOCODE_TYPE } from '@/constants/promocodeTypes'
import { DELIVERY_TYPE } from '@/constants/deliveryType'
import { UNAVAILABLE_DELIVERY_DAYS_TYPES } from '@/constants/deliverySettings'
import { PRESENCES } from '@/constants/productPresences'

import Autocomplete from '@/components/atoms/Autocomplete.vue'
import Icon from '@/components/atoms/Icon.vue'
import DeliveryAddressModal from '@/components/atoms/DeliveryAddressModal.vue'

export default {
  PROMOCODE_TYPE,
  DELIVERY_TYPE,
  UNAVAILABLE_DELIVERY_DAYS_TYPES,
  PRESENCES,
  components: {
    Autocomplete,
    Icon,
    DeliveryAddressModal,
  },
  mixins: [notifications],
  data() {
    return {
      isUsedPromoCode: false,
      checkedPromoCodeProducstIds: [],
      deliveryOptions: [
        { name: 'Самовывоз', value: DELIVERY_TYPE.SELFDELIVERY },
        { name: 'Доставка', value: DELIVERY_TYPE.DELIVERY },
      ],
      paymentStatusOptions: [],
      paymentTypes: [],
      availablePaymentTypes: [],
      orderStatuses: [],
      setSearchItems: null,
      phoneSearch: '',
      productSearch: '',
      searchProducts: [],
      searchUsers: [],
      deliveryAddresses: [],
      usersPage: 1,
      page: 1,
      limit: 20,
      usersCount: null,
      productsCount: null,
      hasUserCode: false,
      maxDiscountPrice: 0,
      isShowDeliveryAdressModal: false,
      isCodeNeedResend: false,
      deliverySettings: {
        restrictedDays: {
          events: [],
        },
        expressDeliveryTimeRange: {
          from: '',
          to: '',
        },
      },
      disabledDates: {
        dates: [],
        to: '',
        ranges: [],
        days: [],
      },
      dayInMs: 24 * 60 * 60 * 1000,
      isAvailableForExpressDelivery: true,
      isAvailableForDelivery: true,
      deliveryPriceData: {
        simpleDeliveryPrice: 0,
        expressDeliveryPrice: 0,
      },
      order: {
        id: '',
        paymentType: {},
        client: {},
        deliveryAddress: null,
        products: [],
        state: {},
        paymentStatus: {},
        deliveryAt: new Date(),
        discount: '',
        deliveryType: '',
        phone: '',
        code: '',
        isExpressDelivery: false,
        deliveryPrice: 0,
      },
      promoCode: {
        code: '',
        success: '',
        error: '',
        data: null,
      },
      paymentSettings: {
        bonusMaxWriteOffCount: 0,
        bonusWriteOffRatio: 0,
      },
      bonuses: {
        isWriteOff: false,
        count: 0,
      },
      errors: {
        bonuses: '',
      },
      amountBonusWriteOff: null,
      rules: {
        deliveryType: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        deliveryAddress: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        paymentType: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        recipientPhone: [
          {
            validator: this.validatePhone,
            trigger: 'change',
          },
        ],
        client: {
          phone: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
            {
              pattern: regexp.phone,
              message: 'Введите корректный телефон',
              trigger: 'change',
            },
          ],
          firstName: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
          email: [
            {
              pattern: regexp.email,
              message: 'Введите корректную почту',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  computed: {
    productAmount() {
      return this.order.products.reduce(
        (acc, cur) =>
          this.getPrice(cur.discountPrice, cur.price, cur.discount ?? 0) *
            cur.quantity +
          acc,
        0,
      )
    },
    isOrderContainsLargeProduct() {
      return this.order.products?.some((e) => e?.isLarge)
    },
    isDeliveryPriceReadOnly() {
      return this.isOrderContainsLargeProduct
        ? false
        : this.isAvailableForDelivery
    },
    maxAmountBonusWriteOff() {
      if (this.bonuses.count > 0) {
        const maxAmountWriteOff = Math.floor(
          (this.productAmount * this.paymentSettings.bonusMaxWriteOffCount) /
            100,
        )

        return this.bonuses.count * this.paymentSettings.bonusWriteOffRatio >=
          maxAmountWriteOff
          ? Math.floor(
              maxAmountWriteOff / this.paymentSettings.bonusWriteOffRatio,
            ) * this.paymentSettings.bonusWriteOffRatio
          : this.bonuses.count * this.paymentSettings.bonusWriteOffRatio
      }

      return 0
    },
    totalPrice() {
      const total =
        this.productAmount -
        (this.bonuses.isWriteOff ? this.amountBonusWriteOff : 0) +
        (this.productAmount ? this.order.deliveryPrice : 0)
      return total > 0 ? total : 0
    },
  },
  async created() {
    await Promise.all([
      this.getListPaymentTypes(),
      this.getPaymentStatusOptions(),
      this.getOrderStatusesList(),
      this.getPaymentSettings(),
      this.getDeliverySettings(),
    ])
  },
  methods: {
    async getPaymentSettings() {
      const { value, error } =
        await delivery.PaymentServiceCore.PaymentSettingsActions.get()

      if (error) return

      this.paymentSettings.bonusMaxWriteOffCount = value.bonusMaxWriteOffCount
      this.paymentSettings.bonusWriteOffRatio = value.bonusWriteOffRatio
    },
    async removeProduct(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.name} ?`)
      if (!isConfirm) return

      if (this.order.products.find((product) => product.id === data.id)) {
        this.order.products = this.order.products.filter(
          (product) => product.id !== data.id,
        )
      }

      if (
        data?.isLarge &&
        this.order.deliveryType === DELIVERY_TYPE.DELIVERY &&
        !this.isOrderContainsLargeProduct
      )
        this.showNotification(
          'В заказе больше нет крупногабаритных товаров. Стоимость доставки будет рассчитана автоматически',
          'warning',
        )

      this.promoCode.success = null

      await this.getAvailabilityByCoordinates()
      await this.getDisabledDates()
      this.setDeliveryPrice()
    },
    getPrice(discountPrice, price, discount = 0) {
      const productPrice =
        discountPrice < price && discountPrice > 0 ? discountPrice : price

      return productPrice - (discount ?? 0)
    },
    changeDeliveryType(deliveryType) {
      this.order.deliveryType = deliveryType

      if (this.order.deliveryType === DELIVERY_TYPE.SELFDELIVERY) {
        this.availablePaymentTypes = this.paymentTypes.filter(
          (paymentType) => paymentType.isAvailableForSelfDelivery,
        )
        this.order.deliveryAddress = null
        this.order.deliveryPrice = 0
        this.order.isExpressDelivery = false
        return
      }

      if (
        this.order.deliveryType === DELIVERY_TYPE.DELIVERY &&
        this.isOrderContainsLargeProduct
      )
        this.showNotification(
          'В корзине присутствует крупногабаритный товар, укажите стоимость доставки',
          'warning',
        )

      this.getDisabledDates()
    },
    async getListPaymentTypes() {
      const { value, error } =
        await delivery.PaymentServiceCore.PaymentTypesActions.getList({
          limit: this.limit,
          page: this.page,
        })

      if (error) return

      this.paymentTypes = value.data.filter(
        (paymentType) => paymentType.isActive,
      )
    },
    async getPaymentStatusOptions() {
      const { value, error } =
        await delivery.PaymentServiceCore.PaymentStatusesActions.getList()

      if (error) return

      this.paymentStatusOptions = value.data
    },
    async getProductsList({ setSearchItems }) {
      this.setSearchItems = setSearchItems
      this.page = 1

      this.setProducts(this.setSearchItems)
    },
    async loadMoreProducts() {
      if (this.limit * this.page < this.productsCount) {
        this.page++

        this.setProducts(this.setSearchItems, true)
      }
    },
    async setProducts(setItems, getOldItems = false) {
      const { value, error } =
        await delivery.ProductsCore.CatalogsActions.getCatalog({
          search: this.productSearch,
          page: this.page,
          limit: this.limit,
        })

      if (error) return

      if (value.products.data.length) {
        value.products.data.push({ isLoading: true })
        this.productsCount = value.products.meta.count
        if (getOldItems) {
          this.searchProducts.pop()
          this.searchProducts = [...this.searchProducts, ...value.products.data]
        } else {
          this.searchProducts = [...value.products.data]
        }

        setItems(this.searchProducts)
      }
    },
    async handleSelectProduct(selectedProduct) {
      if (
        !(selectedProduct.stocks?.length && selectedProduct?.stocks?.[0]?.stock)
      ) {
        this.showNotification('К сожалению, товара нет в наличии', 'error')
        return
      }

      if (
        selectedProduct.presence === PRESENCES.REMOTE_WAREHOUSE &&
        this.order.isExpressDelivery
      ) {
        this.showNotification(
          `Товар: ${selectedProduct.name} не доступен для экспресс доставки`,
          'error',
        )
        return
      }

      if (
        this.order.products.some((product) => product.id === selectedProduct.id)
      ) {
        this.showNotification('Товар уже был добавлен', 'error')
        return
      }

      if (
        selectedProduct?.isLarge &&
        this.order.deliveryType === DELIVERY_TYPE.DELIVERY
      )
        this.showNotification(
          'В корзину был добавлен крупногабаритный товар, укажите стоимость доставки',
          'warning',
        )

      this.maxDiscountPrice = selectedProduct.discountPrice
      this.order.products.push({
        ...selectedProduct,
        quantity: 1 * (selectedProduct?.stepQuantity ?? 1),
      })
      this.promoCode.success = null

      await this.isExpressDeliveryDisabled()
      await this.getAvailabilityByCoordinates()
      await this.getDisabledDates()
      this.setDeliveryPrice()
    },
    createDateAsUTC(date) {
      return new Date(
        Date.UTC(
          date?.getFullYear(),
          date?.getMonth(),
          date?.getDate(),
          date?.getHours(),
          date?.getMinutes(),
          date?.getSeconds(),
        ),
      )
    },
    hasFormError() {
      let isError = false

      if (this.amountBonusWriteOff > this.maxAmountBonusWriteOff) {
        isError = true
        this.errors.bonuses = `Можно списать до ${this.paymentSettings.bonusMaxWriteOffCount} % от стоимости заказа, до ${this.maxAmountBonusWriteOff} ₽`
      }

      if (this.isCodeNeedResend) {
        isError = true
      }

      return isError
    },
    resetErrors() {
      this.errors = {
        bonuses: '',
      }
    },
    async submitForm() {
      this.resetErrors()
      if (this.hasFormError()) return

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          if (!this.order.products.length) {
            this.showNotification('В заказе нет ни одного товара', 'error')
            loading.close()
            return
          }

          const isHasProductsWithOutPromoCode = this.order?.products?.filter(
            (orderProduct) =>
              !this.checkedPromoCodeProducstIds.includes(orderProduct.id),
          )

          if (isHasProductsWithOutPromoCode.length && this.isUsedPromoCode) {
            this.showNotification(
              'Пожалуйста, примените промокод заново, чтобы промокод для всех продуктов применился корректно',
              'warning',
            )
            loading.close()
            return
          }

          const data = {
            userAccountID: this.order.client.id,
            client: this.order.client,
            code: Number(this.order.code),
            phone: this.order.phone,
            comment: this.order.comment,
            deliveryAddress: {
              ...this.order?.deliveryAddress,
              additionalInfo:
                this.order.deliveryAddress?.additionalInfo || null,
            },
            deliveryPrice: Number(this.order.deliveryPrice),
            deliveryAt: this.order.deliveryAt,
            isExpressDelivery: this.order.isExpressDelivery,
            deliveryType: this.order.deliveryType,
            paymentStatus: this.order.paymentStatus.id,
            paymentTypeId: this.order.paymentType.id,
            state: this.order.state.id,
            products: this.order.products.map((product) => ({
              id: product.id,
              quantity: product.quantity,
            })),
          }

          if (this.promoCode?.code) {
            data.promocode = this.promoCode?.code
          }

          if (this.bonuses.isWriteOff && this.amountBonusWriteOff > 0) {
            data.bonusWriteOff = Math.round(
              this.amountBonusWriteOff /
                this.paymentSettings.bonusWriteOffRatio,
            )
          }

          if (data.deliveryType === DELIVERY_TYPE.SELFDELIVERY) {
            data.deliveryAddress = null
            data.deliveryAddressId = null
            data.deliveryAt = null
          }

          if (data.deliveryType !== DELIVERY_TYPE.DELIVERY) {
            data.deliveryAt = this.createDateAsUTC(data.deliveryAt)
          }

          const result =
            await delivery.PaymentServiceCore.GeneralOrdersActions.createOrder(
              data,
            )

          loading.close()

          if (result.error) {
            this.isCodeNeedResend = true
            return
          }

          this.showNotification('Заказ успешно создан', 'success')
          this.$router.push('/addwine/orders')
        }
      })
    },
    async getOrderStatusesList() {
      const { value, error } =
        await delivery.PaymentServiceCore.OrderStatesActions.getList()

      if (error) return

      this.orderStatuses = value.data
    },
    async changeProductQuantityAndDiscountPrice(product) {
      const additionalProduct = this.order.products.find(
        (prod) => prod.id === product.id,
      )
      additionalProduct.quantity = Number(product.quantity)
      additionalProduct.discountPrice = Number(product.discountPrice).toFixed(1)

      await this.getAvailabilityByCoordinates()
      this.setDeliveryPrice()
    },
    async getUsersList({ setSearchItems }) {
      this.setSearchItems = setSearchItems
      this.usersPage = 1

      this.setUsers(this.setSearchItems)
    },
    async loadMoreUsers() {
      if (this.limit * this.usersPage < this.usersCount) {
        this.usersPage++

        this.setUsers(this.setSearchItems, true)
      }
    },
    async setUsers(setItems, getOldItems = false) {
      const { value, error } = await delivery.AccountsCore.UsersActions.getList(
        {
          search: this.phoneSearch,
          page: this.usersPage,
          limit: this.limit,
        },
      )

      if (error) return

      if (value.data.length) {
        value.data.push({ isLoading: true })
        this.usersCount = value.meta.count
        if (getOldItems) {
          this.searchUsers.pop()
          this.searchUsers = [...this.searchUsers, ...value.data]
        } else {
          this.searchUsers = [...value.data]
        }

        setItems(this.searchUsers)
      }
    },
    async handleSelectUser(selectedUser) {
      this.phoneSearch = selectedUser.phone
      this.order.phone = selectedUser.phone
      this.order.client = selectedUser

      await this.getBonusesCount()
    },
    async getDeliveryAddressesById(id) {
      const { value, error } = await delivery.AccountsCore.UsersActions.getById(
        id,
      )

      if (error) return

      this.deliveryAddresses = value.deliveryAddresses
        ? value.deliveryAddresses
        : []
    },
    async getBonusesCount() {
      const { value, error } =
        await delivery.AccountsCore.BonusTransactionsActions.getCount(
          this.order.client.id,
        )

      if (error) {
        return
      }

      this.bonuses.count = value
    },
    async getUserCode() {
      this.$refs.form.validateField('recipientPhone', async (notValidItem) => {
        if (notValidItem) return
        const loading = this.$loading({
          lock: true,
        })
        const { value, error } =
          await delivery.AccountsCore.UsersActions.checkSignUp(this.order.phone)

        loading.close()

        if (error) {
          return
        }

        this.showNotification('Код успешно выслан', 'success')
        this.isCodeNeedResend = false
        this.hasUserCode = true
        this.order.client = value
        await this.getDeliveryAddressesById(this.order.client.id)
      })
    },
    validatePhone(_, value, callback) {
      if (!this.order.phone) {
        callback(new Error('Пожалуйста, выберите получателя'))
      } else {
        callback()
      }
    },
    handleSetInput(event) {
      this.phoneSearch = event
      if (!this.phoneSearch) {
        this.order.client = {}
      }
    },
    async submitPromo() {
      this.promoCode.error = ''
      this.promoCode.success = ''

      if (!this.promoCode.code) {
        this.promoCode.error = 'заполните это поле'
        this.promoCode.data = null
        return
      }

      if (!this.order.products?.length) {
        this.promoCode.error = 'Для начала выберите продукты для заказа'
        this.promoCode.data = null
        return
      }

      const products = this.order.products.map(({ quantity, id }) => ({
        id,
        quantity,
      }))

      const totalProductsDiscount = this.order?.products?.reduce(
        (sum, { discount, quantity }) =>
          (sum += (discount ?? 0) * (quantity ?? 1)),
        0,
      )

      const productsIds = this.order?.products?.map(({ id }) => id)

      const data = {
        products,
        code: this.promoCode.code,
        totalOrderPrice: this.totalPrice + totalProductsDiscount,
      }

      const { value, error } =
        await delivery.PaymentServiceCore.PromoActions.check(data)

      if (error) {
        this.promoCode.error = 'недействительный промокод'
        return
      }

      this.promoCode.success = 'промокод успешно применен'
      this.order.products = value
      this.checkedPromoCodeProducstIds = productsIds
      this.isUsedPromoCode = true
    },
    getDeliveryAddressString() {
      return this.order.deliveryAddress?.city &&
        this.order.deliveryAddress?.street
        ? this.order.deliveryAddress.city +
            ', ' +
            this.order.deliveryAddress.street
        : ''
    },
    async getDeliverySettings() {
      const { value, error } =
        await delivery.PaymentServiceCore.DeliverySettingsActions.get()

      if (error) return

      this.disabledDates.days = value.restrictedDays?.weekdays ?? []
      this.deliverySettings.expressDeliveryTimeRange =
        value.expressDeliveryTimeRange
      this.deliverySettings.restrictedDays.events =
        value?.restrictedDays?.events?.map((event) => ({
          from: new Date(event.from.slice(0, -1)),
          to: new Date(event.to.slice(0, -1)),
          type: event.type,
        })) ?? []
    },
    async handleSubmitDeliveyAddressModal(form) {
      const loading = this.$loading({
        lock: true,
      })

      const response = await delivery.AccountsCore.UsersActions.createAddress(
        this.order.client.id,
        form,
      )

      loading.close()

      if (response.error) return

      await this.getDeliveryAddressesById(this.order.client.id)
      this.isShowDeliveryAdressModal = false
      this.showNotification(
        'Адрес успешно создан, теперь его можно выбрать в поле адрес доставки ',
        'success',
      )
    },
    getDisabledDatesForDelivery() {
      this.disabledDates.dates.push(new Date())

      const deliveryDays = 1

      const estimatedDeliveryDate = this.getEstimatedDeliveryDate(deliveryDays)

      this.setDeliveryDate(estimatedDeliveryDate)
    },
    getDisabledDatesForExpressDelivery() {
      const currentDate = new Date()

      if (!this.isCurTimeInTimeRangeExpressDelivery(currentDate)) {
        this.disabledDates.dates.push(currentDate)

        const deliveryDays = 1

        const estimatedDeliveryDate =
          this.getEstimatedDeliveryDate(deliveryDays)

        this.setDeliveryDate(estimatedDeliveryDate)

        return
      }
      this.setDeliveryDate(currentDate)
    },
    getDisabledDatesForRemoteWarehouse() {
      const deliveryDays = 3

      const estimatedDeliveryDate = this.getEstimatedDeliveryDate(deliveryDays)

      const disableToDate = new Date(
        estimatedDeliveryDate.getTime() - this.dayInMs,
      )
      this.disabledDates.to = disableToDate

      this.setDeliveryDate(estimatedDeliveryDate)
    },
    getEstimatedDeliveryDate(deliveryDays) {
      return new Date(Date.now() + deliveryDays * this.dayInMs)
    },
    setDeliveryDate(date) {
      const currentRange = this.disabledDates.ranges.find(
        (range) => date >= range.from && date < range.to,
      )

      if (currentRange) {
        return this.setDeliveryDate(currentRange.to)
      }

      if (!this.disabledDates.days?.includes(date.getDay())) {
        this.order.deliveryAt = new Date(
          new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 1),
        )
        return
      }

      const newDeliveryDate = new Date(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          0,
          1,
        ).getTime() + this.dayInMs,
      )

      return this.setDeliveryDate(newDeliveryDate)
    },
    isCurTimeInTimeRangeExpressDelivery(currentDate) {
      const expressDeliveryStart =
        this.deliverySettings.expressDeliveryTimeRange.from.split(':')

      const expressDeliveryEnd =
        this.deliverySettings.expressDeliveryTimeRange.to.split(':')

      const expressDeliveryStartDate = new Date()
      expressDeliveryStartDate.setHours(
        expressDeliveryStart[0],
        expressDeliveryStart[1],
        0,
        0,
      )

      const expressDeliveryEndDate = new Date()
      expressDeliveryEndDate.setHours(
        expressDeliveryEnd[0],
        expressDeliveryEnd[1],
        0,
        0,
      )

      return (
        currentDate > expressDeliveryStartDate &&
        currentDate < expressDeliveryEndDate
      )
    },
    getDisabledDates() {
      this.disabledDates.ranges =
        (this.order.isExpressDelivery
          ? this.deliverySettings?.restrictedDays?.events
          : this.deliverySettings?.restrictedDays?.events?.filter(
              (event) =>
                event.type ===
                UNAVAILABLE_DELIVERY_DAYS_TYPES.DELIVERY_RESTRICTED,
            )) ?? []

      this.disabledDates.dates = []

      this.disabledDates.to = new Date(Date.now() - this.dayInMs)

      if (this.order.isExpressDelivery) {
        return this.getDisabledDatesForExpressDelivery()
      }

      const hasProductOnRemoteWarehouse = !!this.order.products.find(
        (product) => product.presence === PRESENCES.REMOTE_WAREHOUSE,
      )

      if (!hasProductOnRemoteWarehouse) {
        this.getDisabledDatesForDelivery()
        return
      }

      return this.getDisabledDatesForRemoteWarehouse()
    },
    setDeliveryPrice() {
      if (this.isOrderContainsLargeProduct) return
      this.order.deliveryPrice = this.order.isExpressDelivery
        ? this.deliveryPriceData.expressDeliveryPrice ||
          this.order.deliveryPrice
        : this.deliveryPriceData.simpleDeliveryPrice || this.order.deliveryPrice
    },
    async getAvailabilityByCoordinates() {
      const query = {
        geoLat: this.order.deliveryAddress?.coordinates.lat,
        geoLon: this.order.deliveryAddress?.coordinates.lng,
        price: this.productAmount,
      }
      const { value, error } =
        await delivery.PaymentServiceCore.DeliveryPricesActions.getAvailabilityByCoordinates(
          query,
        )

      if (error) return

      this.isAvailableForExpressDelivery = value.isAvailableForExpressDelivery
      this.isAvailableForDelivery = value.isAvailableForDelivery

      this.deliveryPriceData = {
        simpleDeliveryPrice: value.deliveryPrice,
        expressDeliveryPrice: value.expressDeliveryPrice,
      }

      this.availablePaymentTypes = value.paymentTypes ?? this.paymentTypes
    },
    async isExpressDeliveryDisabled() {
      const currentDate = new Date()

      const hasProductOnRemoteWarehouse = this.order.products?.some(
        (product) => product.presence === PRESENCES.REMOTE_WAREHOUSE,
      )

      const isDeliveryDisabledRangeExist =
        this.deliverySettings.restrictedDays?.events?.some(
          (range) => currentDate >= range.from && currentDate < range.to,
        )

      const isDisabled =
        !this.isAvailableForExpressDelivery ||
        isDeliveryDisabledRangeExist ||
        hasProductOnRemoteWarehouse ||
        !this.isCurTimeInTimeRangeExpressDelivery(currentDate)

      if (!this.isAvailableForExpressDelivery && this.order.isExpressDelivery) {
        this.order.isExpressDelivery = false
        this.showNotification(
          'в заказе есть товар(ы) недоступные для express доставки',
          'error',
        )
        return
      }
      if (isDeliveryDisabledRangeExist && this.order.isExpressDelivery) {
        this.order.isExpressDelivery = false
        this.showNotification(
          'невозможно оформить заказ, так как текущая дата попадает в диапазон недоступных для express доставки дат',
          'error',
        )
        return
      }

      if (hasProductOnRemoteWarehouse && this.order.isExpressDelivery) {
        this.order.isExpressDelivery = false
        this.showNotification(
          'Экспресс доставка не доступна, так как в заказе есть товар(ы) расположенные на удаленном складе',
          'error',
        )
        return
      }

      if (
        !this.isCurTimeInTimeRangeExpressDelivery(currentDate) &&
        this.order.isExpressDelivery
      ) {
        this.order.isExpressDelivery = false
        this.showNotification(
          'невозможно оформить express заказ, так как текущее время не попадает в диапазон доступный для express доставки',
          'error',
        )
        return
      }

      if (isDisabled && this.order.isExpressDelivery) {
        this.order.isExpressDelivery = false
        this.showNotification(
          'невозможно оформить express заказ на эту дату',
          'error',
        )
        return
      }

      return isDisabled
    },
    async handleChangeIsExpressDelivery() {
      if (await this.isExpressDeliveryDisabled()) {
        this.order.isExpressDelivery = false
        return
      }

      this.getDisabledDates()
      this.setDeliveryPrice()
    },
    handleChangeDeliveryDate(date) {
      this.order.deliveryAt = date

      this.order.isExpressDelivery =
        new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)

      this.setDeliveryPrice()
    },
    async handleSelectDeliveryAddress() {
      await this.getAvailabilityByCoordinates()
      if (!this.isAvailableForDelivery || this.isOrderContainsLargeProduct) {
        this.order.deliveryPrice = 0
      }
      if (this.isOrderContainsLargeProduct) {
        this.showNotification(
          'В корзине присутствует крупногабаритный товар, укажите стоимость доставки',
          'warning',
        )
        return
      }

      if (!this.isAvailableForDelivery) {
        this.order.isExpressDelivery = false
        this.showNotification(
          'К сожалению, стоимость доставки в данную локацию не указана, но вы можете указать цену доставки снизу',
          'warning',
        )
        return
      }
      await this.getDisabledDates()
      await this.isExpressDeliveryDisabled()

      this.setDeliveryPrice()
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }

  .select {
    width: 100%;
  }

  .price {
    text-align: right;
    padding: 0.5rem 0 0 0;
    margin-bottom: 0;
  }

  .routeLink {
    margin: 0 0.5rem;
  }

  .search {
    width: 90%;

    input {
      width: 100%;
    }
  }

  .form {
    & > div > label {
      text-align: left;
    }
    .bonuses {
      padding: 1.5rem;
      background-color: $white;
      margin-top: 1.5rem;
      .title {
        @include text-button;
      }
      .balance {
        @include text-default;
        color: $gray;
        margin-top: 0.5rem;
      }
      .input {
        margin-top: 1.5rem;
      }
      .content {
        max-width: max-content;
        .buttons {
          border-radius: 0.125rem;
          padding: 0.25rem;
          margin-top: 0.75rem;
          display: inline-flex;
          border: 0.063rem solid $light-gray;
          .button {
            button {
              padding: 0.625rem;
              @include mobile {
                padding: 0.45rem;
              }
            }
            &.active {
              background-color: $black;
              border-radius: 0.063rem;
              color: $white;
              button {
                color: $white;
              }
            }
          }
        }
      }
    }
    .promoCode {
      padding: 1.5rem;
      background-color: $white;
      margin-top: 1.5rem;
      .title {
        @include text-button;
        margin-bottom: 0.8rem;
      }
      .form {
        color: $gray;
        display: flex;
        input {
          width: 18.5rem;
        }
        label {
          white-space: nowrap;
        }
        button {
          width: 6.8rem;
          padding: 1.625rem 0;
        }
      }
    }

    .codeBtn {
      margin-left: 1rem;
    }

    .deliveryBtn {
      margin-top: 1rem;
    }

    .infoUser {
      max-width: 43rem;
      margin-bottom: 1rem;
    }

    .warningText {
      color: $warning;
      margin: 1rem 0;
    }
    .dangerText {
      color: $error;
    }
  }

  .buttons {
    display: flex;
    .icon {
      fill: $light-gray;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      margin-right: 1rem;
    }
  }
}
</style>
